<template>
  <cms-page :page-name="path" />
</template>

<script setup lang="ts">
const route = useRoute();
const {
  $unlocalePath
} = useNuxtApp();

const path = computed(() => {
  const _path = $unlocalePath(route.path);
  if (['', '/'].includes(_path)) {
    return 'homepage'
  }
  return _path
});
</script>

<style scoped>

</style>
